import React from "react";
import { HashLink } from "react-router-hash-link";

const Headliner = () => {
  const getGreeting = () => {
    const date = new Date();
    const hour = date.getHours();

    if (hour < 12) {
      return "Good Morning";
    } else if (hour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
    <header className="headerContainer">
      <h1 className="headline">
        {getGreeting()}, I'm <span className="nameHighlight">Josie Daw</span>.
      </h1>
      <h2 className="subheadline">
        I'm a Full Stack Dev, App Dev, and Community Builder.
      </h2>
      <HashLink smooth to="#aboutMe" className="aboutLink">
        <button className="aboutMeButton">About Me</button>
      </HashLink>
    </header>
  );
};

export default Headliner;

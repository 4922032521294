import React, { useState } from "react";

const texts = [
  "I was one of the Top 100 Technologists to Watch in 2023 by Women Who Code.",
  "I've won or placed second in every hackathon I've ever participated in.",
  "I have a Master of Philosophy in Modern British History from the University of Cambridge.",
  "I thru-hiked the Appalachian Trail (over 3,000km/2,000 miles) in the USA.",
  "I founded Constant Coding to help more people from under-represented backgrounds get into tech.",
  "I have personally helped dozens of people get into tech through my mentorship with Constant Coding.",
  "I have worked as an assistant to Nobel Prize winner Amartya Sen.",
  "I was one of the founding leaders of CodeSeoul, a large coding group in South Korea.",
  "I have climbed Mount Kilimanjaro in Tanzania and Mount Fuji in Japan.",
  "I have assisted dozens of dogs find their forever homes through my work with South Korean dog rescues.",
];
const InterestingThings: React.FC = () => {
  const [index, setIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shuffledTexts, setShuffledTexts] = useState<string[]>(texts);

  const handleRandom = () => {
    setIsLoading(true);
    const newArray = [...texts];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    const randomIndex = newArray.findIndex(
      (text) => text !== shuffledTexts[index]
    );
    setIndex(randomIndex);
    setShuffledTexts(newArray);
    setIsLoading(false);
  };

  return (
    <div className="interestingThingsSection">
      <h3 className="interestingThingsHeader">
        Something Interesting About Me
      </h3>
      <p className="interestingThingsText">{shuffledTexts[index]}</p>
      <div className="buttonContainer">
        <button onClick={handleRandom} className="randomFactButton">
          {isLoading ? "Randomizing..." : "Show Another Fact"}
        </button>
      </div>
    </div>
  );
};

export default InterestingThings;

import { createBrowserHistory } from "history";
import React from "react";
import ReactGA from "react-ga";
import { Router } from "react-router-dom";
import "../App.css";
import About from "../components/About";
import Headliner from "../components/Headliner";
import Portfolio from "../components/Portfolio";

ReactGA.initialize("UA-175678595-1");
const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname + location.search);
});

export default class App extends React.Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <Router history={history}>
        <Headliner />
        <About />
        <Portfolio />
      </Router>
    );
  }
}
